<template>
    <div class="flex-container page_navigator">
       <div v-if="(current_page > 1)">
        <a @click="Navigate(current_page - 1)" class="dashboard-menu">{{ $locale.prev }}</a>
       </div>
       <div v-if="(current_page > 1)">
        <a @click="Navigate(1)" class="dashboard-menu">1</a>
       </div>
       <div v-if="(all_pages > 1)">
        <a  class="dashboard-menu menu_selector">{{ current_page }}</a>
       </div>
       <div v-if="(all_pages > 1 && current_page != all_pages)">
        <a @click="Navigate(all_pages)" class="dashboard-menu">..{{ all_pages }}</a>
       </div>
       <div v-if="(all_pages > 1 && current_page != all_pages)">
        <a @click="Navigate(current_page + 1)" class="dashboard-menu">{{ $locale.next }}</a>
       </div>
    </div>
</template>

<script>
export default {
  props: {
    all_pages: Number,
    current_page: Number
  },
  methods: {
    Navigate (pageNumb) {
      this.$emit('navigate', pageNumb)
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style>
.page_navigator{
    margin-top: 10px;
    justify-content:center ;
}
</style>
