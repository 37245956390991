<template>
  <div class="container flex-container">
    <div class="content-container content widget">
      <div class="title text_center">Where to exchange kaspa?</div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://www.mexc.com/exchange/KAS_USDT">MEXC Global</a>
      </div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://www.coinex.com/exchange/KAS-USDT">CoinEx</a>
      </div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://www.kucoin.com/trade/KAS-USDT">Kucoin</a>
      </div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://www.gate.io/trade/KAS_USDT">Gate.io</a>
      </div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://www.bybit.com/en-US/trade/spot/KAS/USDT">ByBit</a>
      </div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://www.bitget.com/">Bitget</a>
      </div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://www.digifinex.com/en-ww/trade/USDT/KAS">Digifinex</a>
      </div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://xeggex.com/market/KAS_USDT">Xeggex</a>
      </div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://uphold.com/">Uphold</a>
      </div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://x.vite.net/trade?symbol=KAS-000_USDT-000">ViteX</a>
      </div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://www.lbank.info/">LBANK</a>
      </div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://www.bydfi.com/en-US/trade/spot/KASUSDT">BYDFI</a>
      </div>
    </div>
    <div class="content-container content widget">
      <div class="title text_center">What wallet to use?</div>
      <div class="text_center">
        Kaspium <a target="_blank" class="resourse"
          href="https://play.google.com/store/apps/details?id=io.kaspium.kaspiumwallet">Android</a>/
        <a target="_blank" class="resourse" href="https://apps.apple.com/us/app/kaspium/id1671845538">IOS</a>
      </div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://wallet.kaspanet.io/">WEB/MOBILE wallet</a>
      </div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://kdx.app/#home">Desktop wallet KDX</a>
      </div>
      <div class="text_center">
        <a target="_blank" class="resourse" href="https://github.com/kaspanet/rusty-kaspa/releases">Command line
          wallet</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    document.title = 'FAQ'
  }
}
</script>

<style>
.faq-container {
  padding: 30px;
}
</style>
