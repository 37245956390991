<template>
  <div class="container-fluid header-container">
    <div class="logo_container">
      <div>
        <a class="logo" href="./">kaspa pool</a>
      </div>
    </div>
    <div class="burger-btn-container">
      <div>
        <button @click="BurgerClickHandler" class="burger-btn">
          <span class="material-symbols-rounded">menu</span>
        </button>
      </div>
    </div>
    <div :class="{ active: BurgerState }" class="menu-container">
      <div class="btn">
        <router-link @click="CloseBurger" class="link" to="/">{{ $locale.home }}</router-link>
      </div>
      <div class="btn">
        <router-link @click="CloseBurger" class="link" to="/statistics">{{ $locale.pool_statistics }}</router-link>
      </div>
      <div class="btn">
        <router-link @click="CloseBurger" class="link" to="/start_mining">{{ $locale.start_mining }}</router-link>
      </div>
      <div class="btn">
        <router-link @click="CloseBurger" class="link" to="/faq">FAQ</router-link>
      </div>
      <div class="btn">
        <button class="dark_mode_btn" @click="ChangeColorTheme">
          <span class="material-symbols-outlined">{{ ColorTheme.Icon }}</span>
        </button>
        <button class="dark_mode_btn" @click="SettingsClickHandler()">
          <span class="material-symbols-outlined">settings</span>
        </button>
      </div>
    </div>
  </div>
  <div class="content-container content set_menu" v-if="SettingsWindow">
    <button class="dark_mode_btn" @click="SettingsClickHandler()">
      <span class="material-symbols-outlined">close</span>
    </button>

    <div class="text_center">
      <div class="title">{{ $locale.language }}</div>
    </div>
    <div class="buttons settings_menu_button small text_centr" @click="ChangeLang('en')"
      :class="{ 'set': cookies.get('language') === 'en' }">English</div>
    <div class="buttons settings_menu_button small" @click="ChangeLang('ru')"
      :class="{ 'set': cookies.get('language') === 'ru' }">Русский</div>
    <div class="text_center">
      <div class="title">{{ $locale.currency }}</div>
    </div>
    <div v-for="(currency, index) in CurrenciesJson" :key=currency>
      <div @click="ChangeCurrency(index)" class="buttons settings_menu_button" :class="{ 'set': index == $currency }">
        <span class="small">{{ currency.name }}</span>
        <span class="title small">{{ currency.label }} {{ currency.s_label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { useCookies } from 'vue3-cookies'
import locales from '@/locales.js'
import currencies from '@/currencies.json'
import { getCurrentInstance } from 'vue'

export default {
  setup () {
    const { cookies } = useCookies()
    return { cookies }
  },
  data () {
    return {
      width: 0,
      ColorTheme: {},
      BurgerState: false,
      SettingsWindow: false,
      CurrenciesJson: currencies
    }
  },
  methods: {
    BurgerClickHandler () {
      if (this.BurgerState === true) {
        this.BurgerState = false
      } else {
        this.BurgerState = true
      }
    },
    CloseBurger () {
      this.BurgerState = false
    },
    ChangeColorTheme () {
      if (this.ColorTheme.Theme === 'light') {
        // dark
        this.ColorTheme.Theme = 'dark'
        this.ColorTheme.Icon = 'light_mode'
        document.querySelector('html').classList.add('dark_mode')
        this.cookies.set('color_theme', 'dark', 3600 * 24 * 365)
      } else {
        // light
        this.ColorTheme.Theme = 'light'
        this.ColorTheme.Icon = 'dark_mode'
        document.querySelector('html').classList.remove('dark_mode')
        this.cookies.set('color_theme', 'light', 3600 * 24 * 365)
      }
      this.CloseBurger()
      window.location.reload()
    },
    ChangeLang (lang) {
      if (this.cookies.get('language') === lang) {
        return
      }
      this.cookies.set('language', lang, 3600 * 24 * 365)
      window.location.reload()
    },
    ChangeCurrency (currency) {
      if (this.cookies.get('currency') === currency) {
        return
      }
      this.cookies.set('currency', currency, 3600 * 24 * 365)
      window.location.reload()
    },
    SettingsClickHandler () {
      if (this.SettingsWindow === true) {
        this.SettingsWindow = false
      } else {
        this.SettingsWindow = true
      }
    },
    resizeHandler () {
      if ((this.width + 100) < window.innerWidth || (this.width - 100) > window.innerWidth) {
        window.location.reload()
      }
    }
  },
  mounted () {
    this.width = window.innerWidth
    window.addEventListener('resize', this.resizeHandler)
    //
    let lang = this.cookies.get('language')
    const app = getCurrentInstance()
    if (!lang) {
      lang = navigator.language || navigator.userLanguaage
    }
    if (lang === 'ru') {
      app.appContext.config.globalProperties.$locale = locales.ru
    }
    if (lang === 'en') {
      app.appContext.config.globalProperties.$locale = locales.en
    }
    //
    const colorTheme = this.cookies.get('color_theme')
    if (colorTheme === 'light') {
      this.ColorTheme.Theme = 'light'
      this.ColorTheme.Icon = 'dark_mode'
      document.querySelector('html').classList.remove('dark_mode')
    } else {
      this.ColorTheme.Theme = 'dark'
      this.ColorTheme.Icon = 'light_mode'
      document.querySelector('html').classList.add('dark_mode')
    }
    // Currency
    const CurrencyCockie = this.cookies.get('currency')
    if (CurrencyCockie) {
      app.appContext.config.globalProperties.$currency = CurrencyCockie
    }
  }
}
</script>

<style>
.buttons.settings_menu_button {
  height: fit-content;
  text-align: center;
  padding: 5px;
  margin-right: 2px;
  margin-bottom: 5px;
}

.set_menu {
  border: solid 2px var(--color-palite1);
  padding: 10px;
  position: absolute;
  right: 20px;
}

.header-container {
  background-color: var(--color-background2);
  height: 60px;
  width: 100%;
  position: absolute;
  top: 0px;
  box-shadow: 2px 2px 8px 2px var(--color-palite12);
}

.logo_container {
  float: left;
  text-align: center;
  width: 25%;
  padding: 8px;
  display: flex;
  justify-content: center;
}

.logo {
  font-family: 'Play', sans-serif;
  font-size: 34px;
  text-decoration: none;
  color: var(--color-palite3);
}

.logo:hover {
  color: var(--color-palite3);
}

.menu-container {
  float: right;
  width: 63%;
  height: 60px;
}

.btn {
  float: left;
  padding-top: 16px;
  padding-bottom: 19px;
}

.dark_mode_btn {
  border: none;
  background-color: var(--color-background2);
  color: var(--color-palite3);
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.link {
  color: var(--color-palite3);
  text-decoration: none;
  font-family: 'Play', sans-serif;
  font-size: 18px;
  padding-top: 14px;
  padding-bottom: 16px;
  padding-left: 14px;
  padding-right: 14px;
}

.link:hover {
  color: var(--color-palite3);
  box-shadow: inset 0px -3px 0px var(--color-palite3);
}

.burger-btn {
  float: right;
  background-color: var(--color-background2);
  color: var(--color-palite1);
  border: none;
  height: 50px;
  width: 50px;
  margin: 5px;
}

.burger-btn:active {
  color: var(--color-palite3);
}

.burger-btn-container {
  display: none;
}

.material-symbols-rounded {
  font-size: 35px;
  color: var(--color-palite1);
}

@media (max-width: 768px) {
  .set_menu {
    right: 10px;
  }

  .menu-container {
    display: none;
    padding-right: 20px;
    height: 250px;
    float: right;
  }

  .burger-btn-container {
    display: contents;
  }

  .logo_container {
    float: left;
    text-align: center;
    width: 70%;
  }

  .btn {
    background-color: var(--color-background2);
    text-align: end;
    padding-top: 19px;
    padding-bottom: 19px;
    width: 100%;
  }

  .dark_mode_btn {
    margin-right: 15px;
  }
}

.active {
  display: contents;
}
</style>
