<template>
  <div class="container pad">
    <div class="content-container content full_height start_mining_container">
      <!---------------------------------------------------------------------->
      <div class="title small text_center">{{ $locale.enter_wallet_address }}</div>
      <form @submit.prevent class="text_center">
        <input class="text-input input-wallet" name="wallet" type="text" size="67" placeholder="" v-model.trim="wallet"
          @change="UpdateConfig()" />
      </form>
      <div class="title small error text_center">{{ error }}</div>
      <!---------------------------------------------------------------------->
      <div class="title small text_center">{{ $locale.enter_worker_name }}</div>
      <form @submit.prevent class="text_center">
        <input class="text-input input-wallet" name="wallet" type="text" size="30" placeholder=""
          v-model.trim="workername" @change="UpdateConfig()" />
      </form>
      <!---------------------------------------------------------------------->
      <div class="title small text_center">{{ $locale.select_location }}</div>
      <div class="text_center">
        <button class="buttons start_mining_buttons" :class="{ 'set': location == 'europe' }">
          <div class="small button_label" @click="SetLocation('europe')">{{ $locale.europe }}</div>
        </button>
        <button class="buttons start_mining_buttons" :class="{ 'set': location == 'asia' }">
          <div class="small button_label" @click="SetLocation('asia')">{{ $locale.asia }}</div>
        </button>
        <button class="buttons start_mining_buttons" :class="{ 'set': location == 'north_america' }">
          <div class="small button_label" @click="SetLocation('north_america')">{{ $locale.north_america }}</div>
        </button>
        <button class="buttons start_mining_buttons" :class="{ 'set': location == 'south_america' }">
          <div class="small button_label" @click="SetLocation('south_america')">{{ $locale.south_america }}</div>
        </button>
      </div>
      <!---------------------------------------------------------------------->
      <div class="title small text_center">{{ $locale.select_mode }}</div>
      <div class="text_center">
        <button class="buttons start_mining_buttons" :class="{ 'set': mode == 'pplns' }">
          <div class="small button_label" @click="SetMiningMode('pplns')">PPLNS</div>
        </button>
        <button class="buttons start_mining_buttons" :class="{ 'set': mode == 'solo' }">
          <div class="small button_label" @click="SetMiningMode('solo')">SOLO</div>
        </button>
      </div>
      <!---------------------------------------------------------------------->
      <div class="title small text_center">{{ $locale.select_connection_type }}</div>
      <div class="text_center">
        <button class="buttons  start_mining_buttons" :class="{ 'set': conn_type == 'tcp' }">
          <div class="small button_label" @click="SetConnType('tcp')">TCP</div>
        </button>
        <button class="buttons start_mining_buttons" :class="{ 'set': conn_type == 'tls' }">
          <div class="small button_label" @click="SetConnType('tls')">TLS</div>
        </button>
      </div>
      <div class="title small text_center">*{{ $locale.click_for_copy }}</div>
      <!---------------------------------------------------------------------->
      <div class="asic_settings_container">
        <div class="flex-container">
          <div class="settings_elem priority small text_center">Priority</div>
          <div class="settings_elem pool_url small text_center">Pool addr.</div>
          <div class="settings_elem wallet_workername small text_center">
            Wallet/Worker</div>
          <div class="settings_elem miner_passwd small text_center">Password</div>
        </div>
        <!----------------------------->
        <div class="flex-container">
          <div class="settings_elem priority small">1</div>
          <div class="settings_elem pool_url settings_border small" @click="CopyHandler(config.url_1)">{{ config.url_1 }}
          </div>
          <div class="settings_elem wallet_workername settings_border small"
            @click="CopyHandler(wallet + '.' + workername)">
            {{ wallet + '.' + workername }}</div>
          <div class="settings_elem miner_passwd passwd_border small">*</div>
        </div>
        <!----------------------------->
        <div class="flex-container">
          <div class="settings_elem priority small">2</div>
          <div class="settings_elem pool_url settings_border small" @click="CopyHandler(config.url_2)">{{ config.url_2 }}
          </div>
          <div class="settings_elem wallet_workername settings_border small"
            @click="CopyHandler(wallet + '.' + workername)">
            {{ wallet + '.' + workername }}</div>
          <div class="settings_elem miner_passwd passwd_border small">*</div>
        </div>
        <!----------------------------->
        <div class="flex-container">
          <div class="settings_elem priority small">3</div>
          <div class="settings_elem pool_url settings_border small" @click="CopyHandler(config.url_3)">{{ config.url_3 }}
          </div>
          <div class="settings_elem wallet_workername settings_border small"
            @click="CopyHandler(wallet + '.' + workername)">
            {{ wallet + '.' + workername }}</div>
          <div class="settings_elem miner_passwd passwd_border small">*</div>
        </div>
      </div>
      <!---------------------------------------------------------------------->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      wallet: '',
      workername: 'worker_1',
      location: 'europe',
      mode: 'pplns',
      conn_type: 'tcp',
      config: {
        url_1: '',
        url_2: '',
        url_3: ''
      },
      error: ''
    }
  },
  mounted () {
    document.title = this.$locale.start_mining
    this.UpdateConfig()
  },
  methods: {
    UpdateConfig () {
      this.wallet = this.wallet.replace(/[^a-z0-9:]/g, '')
      this.workername = this.workername.replace(/[^a-zA-Z0-9_]/g, '')
      if (this.workername.length > 16) {
        this.workername = this.workername.slice(0, 16)
      }
      if (this.wallet.length !== 0) {
        this.wallet = this.wallet.replace(' ', '')
        if (((this.wallet.length + 6) === 67) || ((this.wallet.length + 6) === 69)) {
          this.wallet = 'kaspa:' + this.wallet
        }
        if (this.wallet.length !== 67) {
          if (this.wallet.length !== 69) {
            this.error = 'Invalid address'
            return
          }
        }
      }
      this.error = ''
      let template = 'stratum+tcp://'
      if (this.conn_type === 'tls') {
        template = 'stratum+tls://'
      }
      if (this.location === 'europe') {
        this.config.url_1 = template + 'eu1.kaspa-pool.org'
        this.config.url_2 = template + 'us1.kaspa-pool.org'
        this.config.url_3 = template + 'asia1.kaspa-pool.org'
      }
      if (this.location === 'asia') {
        this.config.url_1 = template + 'asia1.kaspa-pool.org'
        this.config.url_2 = template + 'eu1.kaspa-pool.org'
        this.config.url_3 = template + 'us1.kaspa-pool.org'
      }
      if (this.location === 'north_america') {
        this.config.url_1 = template + 'us1.kaspa-pool.org'
        this.config.url_2 = template + 'eu1.kaspa-pool.org'
        this.config.url_3 = template + 'br1.kaspa-pool.org'
      }
      if (this.location === 'south_america') {
        this.config.url_1 = template + 'br1.kaspa-pool.org'
        this.config.url_2 = template + 'us1.kaspa-pool.org'
        this.config.url_3 = template + 'eu1.kaspa-pool.org'
      }
      let port = ''
      if (this.conn_type === 'tcp') {
        port = ':444'
      } else {
        port = ':555'
      }
      if (this.mode === 'solo') {
        port += '1'
      } else {
        if (this.conn_type === 'tcp') {
          port += '4'
        } else {
          port += '5'
        }
      }
      this.config.url_1 += port
      this.config.url_2 += port
      this.config.url_3 += port
    },
    CopyHandler (input) {
      navigator.clipboard.writeText(input)
    },
    SetWallet (wallet) {
      this.wallet = wallet
      this.UpdateConfig()
    },
    SetWorkerName (workername) {
      this.workername = workername
      this.UpdateConfig()
    },
    SetLocation (location) {
      this.location = location
      this.UpdateConfig()
    },
    SetMiningMode (mode) {
      this.mode = mode
      this.UpdateConfig()
    },
    SetConnType (connType) {
      this.conn_type = connType
      this.UpdateConfig()
    }
  }
}
</script>

<style>
.pad {
  margin-top: 20px;
}

.button_label {
  vertical-align: middle;
  padding: 5px;
  font-family: 'Jura', sans-serif;
  color: var(--color-palite0);
}

.start_mining_container {
  padding: 10px;
}

.buttons.start_mining_buttons {
  margin: 5px;
}

.asic_settings_container {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: fit-content;
  border: solid 2px var(--color-palite1);
  border-radius: 7px;
}

.settings_elem {
  padding: 5px;
  margin: 5px;
}

.settings_border {
  border: solid 2px var(--color-palite1);
  word-break: break-all;
  cursor: pointer;
}

.passwd_border {
  border: solid 2px var(--color-palite1);
  word-break: break-all;
}

.settings_border:hover {
  box-shadow: 0px 0px 2px 1px var(--color-palite3);
}

.settings_border:active {
  box-shadow: inset 0px 0px 3px var(--color-palite3);
}

.priority {
  flex-basis: 3%;
  margin: auto;
}

.pool_url {
  flex-basis: 33%;
}

.wallet_workername {
  flex-basis: 40%;
}

.miner_passwd {
  flex-basis: 8%;
}

@media (max-width: 992px) {
  .priority {
    flex-basis: 6%;
  }

  .pool_url {
    flex-basis: 35%;
  }

  .wallet_workername {
    flex-basis: 35%;
  }

  .miner_passwd {
    flex-basis: 10%;
  }
}

@media (max-width: 768px) {
  .priority {
    flex-basis: 10%;
  }

  .pool_url {
    flex-basis: 20%;
  }

  .wallet_workername {
    flex-basis: 26%;
  }

  .miner_passwd {
    flex-basis: 16%;
  }
}
</style>
