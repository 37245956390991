<template>
  <Bar :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId" :dataset-id-key="datasetIdKey"
    :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
</template>

<script>
import { Bar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Filler
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale,
  Filler
)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    beginAtZeroY: {
      type: Boolean
    },
    Chart: {
      dataset: {
        type: { Array }
      }
    },
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 338
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Object,
      default: () => { }
    }
  },
  mounted () {
    const date = Date.now()
    let offset = 86400000
    for (let index = 0; index < 24; index++) {
      offset = offset - 3600000
      this.chartData.labels[index] = new Date(date - offset).toLocaleString(
        'sv-SE',
        {
          hour12: false
        }
      )
    }
  },
  data () {
    return {
      chartData: {
        labels: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ],
        datasets: [
          {
            data: this.Chart.dataset,
            label: 'Found blocks'
          }
        ]
      },
      chartOptions: {
        layout: {
          padding: {
            left: 5,
            top: 0,
            right: 15,
            bottom: 5
          },
          margin: 0,
          autoPadding: false
        },
        responsive: true,
        borderWidth: '2',
        pointBorderWidth: '0',
        borderColor: '#1BBCF0',
        pointHitRadius: 100,

        fill: true,
        backgroundColor: '#1BBCF010',
        scales: {
          x: {
            ticks: {
              maxRotation: 0,
              display: false,
              callback: function (val, index, ticks) {
                const tim = this.getLabelForValue(val)
                const tims = tim.split(' ')[1]
                const tims2 = (tims || '').split(':')
                let tims3 = tims2[0] + ':' + tims2[1]
                if ((index + 1) % 4) {
                  tims3 = ''
                }
                return tims3
              },
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-palite0'
              )
            },
            display: true,
            title: {
              display: false
            },
            grid: {
              display: false

              // color: '#343956'
            }
          },
          y: {
            beginAtZero: this.beginAtZeroY,
            ticks: {
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-palite0'
              )
            },
            display: true,
            title: {
              display: false
            },
            grid: {
              drawBorder: false,
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-palite7'
              )
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            displayColors: false,
            titleFont: {
              size: 20
            },
            bodyFont: {
              size: 20
            },
            footerFont: {
              size: 20 // there is no footer by default
            }
          }
        }
      }
    }
  }
}
</script>
<style></style>
