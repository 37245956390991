<template>
  <Line :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId" :dataset-id-key="datasetIdKey"
    :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
</template>

<script>
import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler
)

export default {
  name: 'LineChart',
  components: { Line },
  props: {
    beginAtZeroY: {
      type: Boolean
    },
    beginAtHalfY: {
      type: Boolean
    },
    ChartMA: { type: Boolean },
    Label: { type: String },
    Chart: {
      data: {
        type: { Array }
      },
      hashrate_unit: {
        type: {
          String
        }
      }
    },
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Object,
      default: () => { }
    }
  },
  mounted () {
    /* ------------------------------------------------------------------------- */
    const date = Date.now()
    let offset = 86400000
    for (let index = 0; index < 24; index++) {
      offset = offset - 3600000
      this.chartData.labels[index] = new Date(date - offset).toLocaleString(
        'sv-SE',
        {
          hour12: false
        }
      )
    }
    /* ------------------------------------------------------------------------- */
    if (this.beginAtHalfY) {
      let minPointChart = 1000000
      let maxPointChart = 0
      for (let idx = 0; idx < 24; idx++) {
        if (minPointChart > this.Chart.data[idx]) {
          minPointChart = this.Chart.data[idx]
        }
        if (maxPointChart < this.Chart.data[idx]) {
          maxPointChart = this.Chart.data[idx]
        }
      }
      if (maxPointChart === 0) {
        this.chartOptions.scales.y.suggestedMax = 1
      } else {
        this.chartOptions.scales.y.suggestedMax = maxPointChart * 1.2
      }
      this.chartOptions.scales.y.suggestedMin = minPointChart * 0.6
    }
    /* ------------------------------------------------------------------------- */
    if (this.ChartMA) {
      const steps = 6
      for (let index = 23; index >= 0; index--) {
        let sum = 0
        let len = 0
        for (let step = 0; step < steps; step++) {
          len = index - step
          if (len < 0) {
            len = 0
          }
          sum = sum + this.Chart.data[len]
        }
        this.chartData.datasets[0].data[index] = sum / steps
      }
    }

    let startPoint = 0
    for (startPoint = 0; startPoint < 20; startPoint++) {
      if (this.Chart.data[startPoint] !== 0) {
        break
      }
    }
    if (startPoint > 1) {
      this.chartData.labels = this.chartData.labels.slice(startPoint - 2)
      this.chartData.datasets[0].data = this.chartData.datasets[0].data.slice(
        startPoint - 2
      )
      this.chartData.datasets[1].data = this.Chart.data.slice(startPoint - 2)
    } else {
      this.chartData.datasets[1].data = this.Chart.data
    }
    /* ------------------------------------------------------------------------- */
  },
  data () {
    return {
      chartData: {
        labels: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ],
        datasets: [
          {
            data: [],
            label: 'Average' + ' ' + this.Chart.hashrate_unit,
            order: 1,
            borderColor: getComputedStyle(document.body).getPropertyValue(
              '--color-palite9'
            ),
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx
              const gradient = canvas.createLinearGradient(0, 0, 0, 200)

              gradient.addColorStop(0, '#F0326370')
              gradient.addColorStop(1, '#F0326301')

              return gradient
            }
          },
          {
            data: [],
            label: this.Label + ' ' + this.Chart.hashrate_unit
          }
        ]
      },
      chartOptions: {
        layout: {
          padding: {
            left: 5,
            top: 20,
            right: 25,
            bottom: 5
          },
          margin: 0,
          autoPadding: false
        },
        tension: 0.2,
        responsive: true,
        resizeDelay: 100,
        borderWidth: '3',
        pointBorderWidth: '0',
        borderColor: '#1BBCF0',
        pointHitRadius: 100,

        fill: true,
        backgroundColor: (ctx) => {
          const canvas = ctx.chart.ctx
          const gradient = canvas.createLinearGradient(0, 0, 0, 150)

          gradient.addColorStop(0, '#1BBCF070')
          gradient.addColorStop(1, '#1BBCF001')

          return gradient
        },
        scales: {
          x: {
            display: true,
            ticks: {
              maxRotation: 0,
              autoSkip: false,
              callback: function (val, index, ticks) {
                const tim = this.getLabelForValue(val)
                const tims = tim.split(' ')[1]
                const tims2 = (tims || '').split(':')
                let tims3 = tims2[0] + ':' + tims2[1]
                if ((index + 1) % 6) {
                  tims3 = ''
                }
                return tims3
              },
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-palite0'
              )
            },
            title: {
              display: false
            },
            grid: {
              display: false,
              color: (ctx) => {
                const canvas = ctx.chart.ctx
                const gradient = canvas.createLinearGradient(0, 0, 0, 300)

                gradient.addColorStop(0, '#34395602')
                gradient.addColorStop(1, '#34395664')

                return gradient
              }
              // color: '#343956'
            }
          },
          y: {
            display: true,
            beginAtZero: this.beginAtZeroY,
            ticks: {
              padding: 5,
              callback: (val, index, ticks) => {
                return parseFloat(val.toFixed(2)) + ' ' + this.chartData.datasets[1].label.split(' ')[1]
              },
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-palite0'
              )
            },
            title: {
              display: false
            },
            grid: {
              drawBorder: false,
              display: true,
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-palite7'
              )
              // color: '#343956'
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const labels = context.dataset.label.split(' ')
                return (
                  labels[0] +
                  ': ' +
                  context.parsed.y.toFixed(2) +
                  ' ' +
                  labels[1]
                )
              }
            },
            displayColors: false,
            titleFont: {
              size: 16
            },
            bodyFont: {
              size: 18
            },
            footerFont: {
              size: 18 // there is no footer by default
            }
          }
        }
      }
    }
  }
}
</script>
<style></style>
