<template>
  <Line :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId" :dataset-id-key="datasetIdKey"
    :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
</template>

<script>
import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler
)

export default {
  name: 'PriceChart',
  components: { Line },
  props: {
    Chart: {
      type: Array
    },
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Object,
      default: () => { }
    }
  },
  mounted () {
    for (let idx = 0; idx < this.Chart.length; idx++) {
      this.chartData.labels[idx] = new Date(this.Chart[idx][0]).toLocaleString(
        'sv-SE',
        {
          hour12: false
        }
      )
      this.chartData.datasets[0].data[idx] = this.Chart[idx][1]
    }
    if (this.chartData.datasets[0].data[0] < this.chartData.datasets[0].data[this.Chart.length - 1]) {
      this.chartOptions.borderColor = '#13f09b'
      this.chartOptions.backgroundColor = (ctx) => {
        const canvas = ctx.chart.ctx
        const gradient = canvas.createLinearGradient(0, 0, 0, 110)

        gradient.addColorStop(0, '#13f09b70')
        gradient.addColorStop(1, '#13f09b01')

        return gradient
      }
    } else {
      this.chartOptions.borderColor = '#F03263'
      this.chartOptions.backgroundColor = (ctx) => {
        const canvas = ctx.chart.ctx
        const gradient = canvas.createLinearGradient(0, 0, 0, 110)

        gradient.addColorStop(0, '#F0326370')
        gradient.addColorStop(1, '#F0326301')

        return gradient
      }
    }
  },
  data () {
    return {
      chartData: {
        labels: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
        datasets: [{
          data: []
        }]
      },
      chartOptions: {
        layout: {
          padding: {
            left: 5,
            top: 0,
            right: 10,
            bottom: 18
          },
          margin: 0,
          autoPadding: false
        },
        tension: 0.1,
        responsive: true,
        resizeDelay: 100,
        borderWidth: '3',
        pointBorderWidth: '0',
        borderColor: '#1BBCF0',
        pointHitRadius: 100,

        fill: true,
        backgroundColor: '',
        scales: {
          x: {
            display: false,
            ticks: {
              maxRotation: 0,
              autoSkip: false,
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-palite0'
              )
            },
            title: {
              display: false
            },
            grid: {
              display: true,
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-background2'
              )
            }
          },
          y: {
            display: true,
            beginAtZero: false,
            ticks: {
              callback: (val, index, ticks) => {
                return val.toFixed(3)
              },
              padding: 5,
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-palite0'
              )
            },
            title: {
              display: false
            },
            grid: {
              drawBorder: false,
              display: true,
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-palite7'
              )
              // color: '#343956'
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return context.parsed.y.toFixed(6)
              }
            },
            displayColors: false,
            titleFont: {
              size: 16
            },
            bodyFont: {
              size: 18
            },
            footerFont: {
              size: 18 // there is no footer by default
            }
          }
        }
      }
    }
  }
}
</script>
<style></style>
