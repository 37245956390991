<template>
  <div class="container rewards-container">
    <div class="content-container content full_height rewards" :class="{ blur_bg: !loaded }">
      <div class="flex-container">
        <div class="rew-block">
          <div class="rew-elems">{{ $locale.block }}</div>
        </div>
        <div class="rew-value">
          <div class="rew-elems">{{ $locale.value }}</div>
        </div>
        <div class="rew-partic">
          <div class="rew-elems">{{ $locale.participation }}</div>
        </div>
        <div class="rew-time">
          <div class="rew-elems">{{ $locale.time }}</div>
        </div>
        <div class="rew-solo">
          <div class="rew-elems">{{ $locale.type }}</div>
        </div>
      </div>
      <hr class="hr" />
      <div v-if="loaded">
        <div v-for="(reward, index) in Response.rewards" v-bind:key="index" class="rewards_row flex-container diver">
          <div class="rew-block">
            <div class="rew-elems">
              {{ reward.block }}
            </div>
          </div>
          <div class="rew-value">
            <div class="rew-elems">
              {{ reward.value }}
            </div>
          </div>
          <div class="rew-partic">
            <div class="rew-elems">
              {{ (reward.participation * 100).toFixed(6) }}%
            </div>
          </div>
          <div class="rew-time">
            <div class="rew-elems">
              {{ TimetampToDate(reward.time) }}
            </div>
          </div>
          <div class="rew-solo">
            <div class="rew-elems">
              {{ SoloPplns(reward.solo) }}
            </div>
          </div>
        </div>
        <PageNavigator @navigate="Navigate" :all_pages="Math.ceil(Response.all / Response.limit)"
          :current_page="Math.ceil(Response.offset / Response.limit) + 1" />
      </div>
      <PulseLoader :loading="!loaded" :color="'#1BBCF080'" />
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import PageNavigator from './PageNavigator.vue'

export default {
  components: {
    PulseLoader,
    PageNavigator
  },
  props: {
    wallet: {
      type: String
    }
  },
  data () {
    return {
      loaded: false,
      Response: {
        limit: 0,
        offset: 0,
        all: 0,
        rewards: [
          {
            time: 0,
            block: 0,
            value: 0.0,
            participation: 0.0,
            solo: false
          }
        ]
      }
    }
  },
  mounted () {
    this.Query(32, 0)
  },
  methods: {
    TimetampToDate (timestamp) {
      const date = new Date(timestamp * 1000).toLocaleString('sv-SE', {
        hour12: false
      })
      return date
    },
    SoloPplns (type) {
      if (type === false) {
        return 'PPLNS'
      } else {
        return 'SOLO'
      }
    },
    Navigate (page) {
      this.Query(this.Response.limit, this.Response.limit * (page - 1))
    },
    async Query (limit, offset) {
      const response = await fetch('/api/user/rewards/?wallet=' + this.wallet + '&limit=' + limit + '&offset=' + offset)
      if (response.ok) {
        this.Response = await response.json()
        this.loaded = true
      }
    }
  }
}
</script>

<style>
.rewards-container {
  padding-top: 2px;
}

.rew-elems {
  font-size: 16px;
  text-align: center;
}

.rewards {
  padding: 10px;
}

.rewards_row {
  border-radius: 5px;
  margin-bottom: 5px;
}

.rew-block {
  height: fit-content;
  flex-basis: 20%;
  padding: 4px;
  overflow: hidden;
}

.rew-value {
  height: fit-content;
  flex-basis: 20%;
  padding: 4px;
  overflow: hidden;
}

.rew-partic {
  height: fit-content;
  flex-basis: 20%;
  padding: 4px;
  overflow: hidden;
}

.rew-time {
  height: fit-content;
  flex-basis: 30%;
  padding: 4px;
  overflow: hidden;
}

.rew-solo {
  height: fit-content;
  padding: 4px;
  overflow: hidden;
}

@media (max-width: 992px) {
  .rew-block {
    flex-basis: 15%;
  }

  .rew-value {
    flex-basis: 17%;
  }

  .rew-partic {
    flex-basis: 17%;
  }

  .rew-time {
    flex-basis: 30%;
  }
}

@media (max-width: 670px) {
  .rew-block {
    flex-basis: 15%;
  }

  .rew-value {
    flex-basis: 17%;
  }

  .rew-partic {
    flex-basis: 17%;
  }

  .rew-time {
    flex-basis: 29%;
  }

  .rew-elems {
    font-size: 8px;
  }
}
</style>
