<template>
  <!-- dashboard menu -->
  <div class="container">
    <div class="content-container flex-container">
      <div>
        <a class="dashboard-menu" :class="{ menu_selector: PageSelector == 'main' }" @click="ChangePage('main')">{{
          $locale.main }}</a>
      </div>
      <div>
        <a class="dashboard-menu" :class="{ menu_selector: PageSelector == 'blocks' }" @click="ChangePage('blocks')">{{
          $locale.blocks }}</a>
      </div>
    </div>
  </div>
  <!-- statistics main -->
  <StatisticsMain />
  <!-- miners -->
  <MinersElem v-if="PageSelector == 'main'" />
  <!-- blocks -->
  <BlocksElem v-if="PageSelector == 'blocks'" />
</template>

<script>
import StatisticsMain from '@/Components/StatisticsMain.vue'
import BlocksElem from '@/Components/BlocksElem'
import MinersElem from '@/Components/MinersElem'
export default {
  components: {
    StatisticsMain,
    BlocksElem,
    MinersElem
  },

  data () {
    return {
      PageSelector: 'main'
    }
  },
  methods: {
    ChangePage (page) {
      this.PageSelector = page
    }
  },
  mounted () {
    document.title = this.$locale.pool_statistics
  }
}
</script>
