<template>
  <!---------------------------------------------------------------->
  <div class="container flex-container">
    <div class="content-container content hashrate" :class="{ blur_bg: !loaded }">
      <div>
        <span class="title small">{{ $locale.time }}</span>
        <span class="text_end">
          <span class="title small">{{ $locale.hashrate }}</span>
        </span>
      </div>
      <hr class="div_line">
      <div class="ern_line">
        <span class="title small">{{ $locale.current }}</span>
        <span class="text_end">
          <span class="small">
            {{ base.current_hashrate.hashrate.toFixed(2) }}
            {{ base.current_hashrate.hashrate_unit }}
          </span>
        </span>
      </div>
      <div class="ern_line">
        <span class="title small">{{ $locale._3_hours }}</span>
        <span class="text_end">
          <span class="small">
            {{ base.hashrate3h.hashrate.toFixed(2) }}
            {{ base.hashrate3h.hashrate_unit }}
          </span>
        </span>
      </div>
      <div class="ern_line">
        <span class="title small">{{ $locale._24_hours }}</span>
        <span class="text_end">
          <span class="small">
            {{ base.hashrate24h.hashrate.toFixed(2) }}
            {{ base.hashrate24h.hashrate_unit }}
          </span>
        </span>
      </div>
      <hr class="div_line">
      <div class="ern_line">
        <span class="title small">SOLO {{ $locale.effort }}:</span>
        <span class="text_end">
          <span class="small">
            {{ (100 * base.effort).toFixed() }}%
            ({{ (100 * base.effort_avg).toFixed(0) }}% avg.)
          </span>
        </span>
      </div>
    </div>
    <!---------------------------------->
    <div class="content-container content balance" :class="{ blur_bg: !loaded }">
      <div class="flex-container">
        <div class="doughnut">
          <div v-if="loaded" class="percent">{{ percentCalc() }}</div>
          <DoughnutChart v-if="loaded" :Data="doughnutCalc()" />
        </div>
        <div>
          <div>
            <span class="title small balance_color">{{ $locale.unpaid_balance }}</span>
            <span class="text_end">
              <span class="small">
                {{ formatAmountAndFiat(base.unpaid_balance) }}
              </span>
            </span>
          </div>
          <div>
            <span class="title small">{{ $locale.min_payout }}</span>
            <span class="text_end">
              <span class="small">
                {{ formatAmountAndFiat(base.min_payout) }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div>
        <span class="title small">
          {{ $locale.new_payout }} ~
        </span>
        <span class="text_end">
          <span class="small">
            {{ newPayoutCalculate() }}
          </span>
        </span>
      </div>
      <div>
        <span class="title small">{{ $locale.est_earnings }} ~</span>
        <span class="text_end">
          <span class="small">
            {{ formatAmountAndFiat(base.estimated_earnings) }}
          </span>
        </span>
      </div>
      <div>
        <span class="title small">{{ $locale.total_paid }}</span>
        <span class="text_end">
          <span class="small">
            {{ formatAmountAndFiat(base.total_paid) }}
          </span>
        </span>
      </div>
    </div>
    <!---------------------------------->
    <div class="content-container content other" :class="{ blur_bg: !loaded }">
      <div class="title small text_center">
        KAS/USD {{ price.usd.toFixed(4) }} {{ getChange(price.usd_24h_change) }} (24h)
      </div>
      <PriceChart :height="128" :Chart="price.price_chart" v-if="loaded" />
    </div>
  </div>
  <!---------------------------------------------------------------->
  <div class="container flex-container">
    <div class="content-container content earnings" :class="{ blur_bg: !loaded }">
      <div>
        <span class="title small">{{ $locale.time }}</span>
        <span class="text_end">
          <span class="title small">{{ $locale.earnings }}</span>
        </span>
      </div>
      <hr class="div_line">
      <div class="ern_line">
        <span class="title small">{{ $locale.earnings_1h }}</span>
        <span class="text_end">
          <span class="small">
            {{ formatAmountAndFiat(base.revenue.revenue60m) }}
          </span>
        </span>
      </div>
      <div class="ern_line">
        <span class="title small">{{ $locale.earnings_12h }}</span>
        <span class="text_end">
          <span class="small">
            {{ formatAmountAndFiat(base.revenue.revenue12h) }}
          </span>
        </span>
      </div>
      <div class="ern_line">
        <span class="title small">{{ $locale.earnings_24h }}</span>
        <span class="text_end">
          <span class="small">
            {{ formatAmountAndFiat(base.revenue.revenue24h) }}
          </span>
        </span>
      </div>
      <div class="ern_line">
        <span class="title small">{{ $locale.earnings_week }}</span>
        <span class="text_end">
          <span class="small">
            {{ formatAmountAndFiat(base.revenue.revenue_week) }}
          </span>
        </span>
      </div>
      <div class="ern_line">
        <span class="title small">{{ $locale.earnings_month }}</span>
        <span class="text_end">
          <span class="small">
            {{ formatAmountAndFiat(base.revenue.revenue_month) }}
          </span>
        </span>
      </div>
    </div>
    <!---------------------------------------->
    <div class="content-container content chart" :class="{ blur_bg: !loaded }">
      <LineChart :Chart="base.hashrate_chart" :Label="'Hashrate'" :ChartMA="false" :beginAtHalfY="true" v-if="loaded"
        :height="175" />
    </div>
    <!---------------------------------------------------------------->
  </div>
  <WorkersElem :wallet="wallet" />
</template>

<script>
import LineChart from '@/Components/LineChart.vue'
import PriceChart from './PriceChart.vue'
import WorkersElem from '@/Components/WorkersElem.vue'
import DoughnutChart from '@/Components/DoughnutChart.vue'
import currencies from '@/currencies.json'
export default {
  components: { LineChart, WorkersElem, DoughnutChart, PriceChart },
  props: {
    wallet: {
      type: String
    }
  },
  data () {
    return {
      interval: null,
      interval_wd: null,
      timer_active: false,
      loaded: false,
      base: {
        wallet: '',
        unpaid_balance: 0.0,
        min_payout: 0.0,
        total_paid: 0.0,
        estimated_earnings: 0.0,
        effort: 0.0,
        effort_avg: 0.0,
        revenue: {
          revenue60m: 0.0,
          revenue3h: 0.0,
          revenue12h: 0.0,
          revenue24h: 0.0,
          revenue_week: 0.0,
          revenue_month: 0.0
        },
        current_hashrate: {
          hashrate_unit: 'H/s',
          hashrate: 0.0
        },
        hashrate3h: {
          hashrate_unit: 'H/s',
          hashrate: 0.0
        },
        hashrate24h: {
          hashrate_unit: 'H/s',
          hashrate: 0.0
        },
        hashrate_chart: {
          hashrate_unit: 'H/s',
          data: []
        }
      },
      price: {
        price_chart: [],
        usd: 0.0,
        usd_24h_change: 0.0,
        eur: 0.0,
        gbp: 0.0,
        aud: 0.0,
        try: 0.0,
        krw: 0.0,
        cny: 0.0,
        rub: 0.0,
        uah: 0.0,
        inr: 0.0,
        cad: 0.0,
        brl: 0.0,
        pln: 0.0,
        error: null
      }
    }
  },
  methods: {
    formatAmountAndFiat (input) {
      return this.formatAmount(input) +
        ' (' + this.formatAmount(input * this.price[this.$currency]) + ' ' +
        currencies[this.$currency].s_label + ')'
    },
    formatAmount (input) {
      switch (true) {
        case (input < 10):
          return input.toFixed(2)
        case (input >= 10 && input < 100):
          return input.toFixed(2)
        case (input >= 100 && input < 1000):
          return input.toFixed(2)
        case (input >= 1000 && input < 10000):
          return input.toFixed(2)
        case (input >= 10000 && input < 100000):
          return input.toFixed(1)
        case (input >= 100000 && input < 1000000):
          return input.toFixed()
        case (input >= 1000000 && input < 10000000):
          return (input / 1000).toFixed(2) + 'K'
        case (input >= 10000000 && input < 100000000):
          return (input / 1000).toFixed(1) + 'K'
        case (input >= 100000000 && input < 1000000000):
          return (input / 1000).toFixed() + 'K'
      }
    },
    getChange (input) {
      if (input > 0) {
        return '+' + input.toFixed(2) + '%'
      } else {
        return input.toFixed(2) + '%'
      }
    },
    percentCalc () {
      let percent = 100 * this.base.unpaid_balance / this.base.min_payout
      if (isNaN(percent)) {
        return
      }
      if (percent > 100) {
        percent = 100
      }
      return percent.toFixed() + '%'
    },
    doughnutCalc () {
      let dif = this.base.min_payout - this.base.unpaid_balance
      if (dif < 0) {
        dif = 0
      }
      return [this.base.unpaid_balance, dif]
    },
    newPayoutCalculate () {
      if (this.base.estimated_earnings === 0) {
        return '-- -- --'
      }
      let est =
        (this.base.min_payout - this.base.unpaid_balance) / (this.base.estimated_earnings / 86400000)
      if (est <= 0) {
        est = 0
      }
      const date = new Date(Date.now() + est).toLocaleString('sv-SE', {
        hour12: false
      })
      return date
    },

    async Query () {
      const resp = await fetch('/api/price')
      if (resp.ok) {
        this.price = await resp.json()
      }
      // console.log('query db')
      const response = await fetch('/api/user/base/?wallet=' + this.wallet)
      if (response.ok) {
        this.base = await response.json()
      }
      this.loaded = true
    },

    watchDog () {
      if (!document.hidden && !this.timer_active) {
        this.startTimer()
        this.timer_active = true
        // console.log('start timer db')
      } else {
        if (document.hidden && this.timer_active) {
          this.stopTimer()
          this.timer_active = false
          // console.log('stop timer db')
        }
      }
    },

    startTimer () {
      this.stopTimer()
      this.Query()
      this.interval = window.setInterval(() => {
        this.Query()
      }, 60000)
    },

    stopWatchDog () {
      if (this.interval_wd) {
        window.clearInterval(this.interval_wd)
      }
    },

    stopTimer () {
      if (this.interval) {
        window.clearInterval(this.interval)
      }
    }
  },
  mounted () {
    this.interval_wd = window.setInterval(() => {
      this.watchDog()
    }, 100)
  },
  beforeUnmount () {
    this.stopWatchDog()
    this.stopTimer()
  }
}
</script>

<style>
.percent {
  font-size: 13px;
  position: absolute;
  height: 55px;
  width: 55px;
  text-align: center;
  padding-top: 19px;
}

.balance_color {
  color: var(--color-palite3);
}

.doughnut {
  width: 55px;
  margin-top: 2px;
  margin-bottom: 7px;
}

.div_line {
  margin: 2px;
  min-width: 180px;
  background-color: var(--color-palite7);
  height: 1px;
  border: 0px;
}

.ern_line {
  padding-top: 0px;
}

.ern_text {
  font-size: 18px;
  padding: 0px;
}

.hashrate {
  height: 150px;
  min-width: 300px;
  padding: 10px;
}

.balance {
  min-width: 300px;
  height: 150px;
  width: fit-content;
  padding: 10px;
}

.earnings {
  min-width: 300px;
  height: 170px;
  width: fit-content;
  padding: 10px;
}

.other {
  height: 160px;
  flex-grow: 1;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-right: 0px;
  flex-wrap: nowrap;
}

.chart {
  padding-top: 15px;
  flex-grow: 1;
  flex-wrap: nowrap;
}

.price_title {
  text-align: center;
  padding-top: 10px;
  font-size: 15px;
  color: var(--color-palite0);
}

@media (max-width: 992px) {
  .doughnut {
    width: 50px;
    margin-top: 0px;
  }

  .percent {
    font-size: 11px;
    height: 50px;
    width: 50px;
    padding-top: 18px;
  }

  .hashrate {
    flex-grow: 1;
    height: 125px;
  }

  .balance {
    flex-grow: 1;
    height: 125px;
  }

  .other {
    flex-basis: 100%;
    height: fit-content;
  }

  .earnings {
    flex-basis: 100%;
    height: fit-content;
  }

  .chart {
    flex-basis: 100%;
    height: fit-content;
    padding-top: 0px;
  }
}

@media (max-width: 768px) {
  .doughnut {
    width: 40px;
    margin-top: 0px;
  }

  .percent {
    font-size: 10px;
    height: 40px;
    width: 40px;
    padding-top: 14px;
  }

  .hashrate {
    flex-grow: 1;
  }

  .balance {
    flex-grow: 1;
  }

  .other {
    flex-basis: 100%;
  }

  .chart {
    flex-basis: 100%;
  }
}
</style>
