<template>
  <Bar :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId" :dataset-id-key="datasetIdKey"
    :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
</template>

<script>
import { Bar } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Filler
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale,
  Filler
)

export default {
  name: 'BarChartMonth',
  components: { Bar },
  props: {
    beginAtZeroY: {
      type: Boolean
    },
    Chart: {
      type: Array
    },
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 338
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Object,
      default: () => { }
    }
  },
  mounted () {
    for (let index = 0; index < 30; index++) {
      this.chartData.datasets[0].data[index] = this.Chart[index].earnings.toFixed(2)
      this.chartData.labels[index] =
        new Date(this.Chart[index].time * 1000).toLocaleString(
          'sv-SE',
          {
            hour12: false
          }
        )
      if (index !== 29) {
        this.chartData.labels[index] += ' \n' +
          new Date(this.Chart[index].time * 1000 + 86400 * 1000).toLocaleString(
            'sv-SE',
            {
              hour12: false
            }
          )
      } else {
        this.chartData.labels[index] += ' \n' +
          new Date(Date.now()).toLocaleString(
            'sv-SE',
            {
              hour12: false
            }
          )
      }

      if (this.Chart[index].solo_effort !== 0) {
        this.chartData.labels[index] += '\nSOLO effort: ' + parseFloat(this.Chart[index].solo_effort).toFixed(1) + '%'
      } else {
        if (this.Chart[index].pplns_effort !== 0) {
          this.chartData.labels[index] += '\nPPLNS effort: ' + parseFloat(this.Chart[index].pplns_effort).toFixed(1) + '%'
        }
      }
    }
  },
  data () {
    return {
      chartData: {
        labels: [
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        ],
        datasets: [
          {
            data: [],
            label: 'Profit'
          }
        ]
      },
      chartOptions: {
        layout: {
          padding: 5,
          margin: 0
        },
        responsive: true,
        borderWidth: '2',
        pointBorderWidth: '0',
        borderColor: '#1BBCF0',
        pointHitRadius: 100,

        fill: true,
        backgroundColor: '#1BBCF010',
        scales: {
          x: {
            ticks: {
              maxRotation: 0,
              display: false,
              callback: function (val, index, ticks) {
                const tim = this.getLabelForValue(val)
                const tim1 = tim.split(' ')[0]
                const tim2 = tim1.split('-')[1] + '-' + tim1.split('-')[2]
                if (index % 2) {
                  return ''
                }
                return tim2
              },
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-palite0'
              )
            },
            display: true,
            title: {
              display: false
            },
            grid: {
              display: false

              // color: '#343956'
            }
          },
          y: {
            beginAtZero: this.beginAtZeroY,
            ticks: {
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-palite0'
              )
            },
            display: true,
            title: {
              display: false
            },
            grid: {
              drawBorder: false,
              color: getComputedStyle(document.body).getPropertyValue(
                '--color-palite7'
              )
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            displayColors: false,
            titleFont: {
              size: 16,
              family: 'Play'
            },
            bodyFont: {
              size: 18,
              family: 'Jura'
            },
            footerFont: {
              size: 20 // there is no footer by default
            }
          }
        }
      }
    }
  }
}
</script>
<style></style>
