<template>
  <div class="container">
    <div class="content-container content miners" :class="{ blur_bg: !loaded }">
      <div class="miners_title title">{{ $locale.top_miners }}</div>
      <div class="flex-container">
        <div class="miners_miner">
          <div class="miners_elem">{{ $locale.miner }}</div>
        </div>
        <div class="miners_hashrate">
          <div class="miners_elem">{{ $locale.hashrate }}</div>
        </div>
        <div class="miners_type">
          <div class="miners_elem">{{ $locale.type }}</div>
        </div>
        <div class="miners_workers">
          <div class="miners_elem">{{ $locale.workers }}</div>
        </div>
      </div>
      <hr class="hr" />
      <div v-for="(miner, index) in Response.miners" v-bind:key="index" class="miners_row flex-container diver">
        <div class="miners_miner">
          <a :href="'https://kaspa-pool.org/#/dashboard/' + miner.wallet" target="_blank" class="miners_elem">{{
            AddressCut(miner.wallet) }}</a>
        </div>
        <div class="miners_hashrate">
          <div class="miners_elem">
            {{ miner.hashrate.hashrate.toFixed(2) }}
            {{ miner.hashrate.hashrate_unit }}
          </div>
        </div>
        <div class="miners_type">
          <div class="miners_elem">
            {{ SoloPplns(miner.solo) }}
          </div>
        </div>
        <div class="miners_workers">
          <div class="miners_elem">
            {{ miner.workers }}
          </div>
        </div>
      </div>
      <PulseLoader :loading="!loaded" :color="'#1BBCF080'" />
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
  components: {
    PulseLoader
  },
  props: {
    wallet: {
      type: String
    }
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      loaded: false,
      Response: {
        miners: [
          {
            wallet: '',
            workers: 0,
            hashrate: {
              hashrate: 0.0,
              hashrate_unit: 'H/s'
            },
            server: {
              type: String
            },
            solo: false
          }
        ]
      }
    }
  },
  async mounted () {
    const response = await fetch('/api/top_miners')
    if (response.ok) {
      this.Response = await response.json()
      this.loaded = true
    }
  },
  methods: {
    SoloPplns (type) {
      if (type === false) {
        return 'PPLNS'
      } else {
        return 'SOLO'
      }
    },
    AddressCut (address) {
      if (this.windowWidth < 1200) {
        if (this.windowWidth < 600) {
          return address.slice(0, 7) + '***' + address.slice(60)
        } else {
          return address.slice(0, 12) + '***' + address.slice(54)
        }
      } else {
        return address
      }
    }
  }
}
</script>

<style>
.miners_elem {
  font-size: 17px;
  text-align: start;
  padding: 6px;
  text-decoration: none;
}

.miners_row {
  border-radius: 5px;
  margin-bottom: 5px;
}

.miners_title {
  text-align: center;
}

.miners {
  padding: 10px;
}

.miners_miner {
  height: fit-content;
  flex-basis: 63%;
  padding-left: 10px;
}

.miners_hashrate {
  height: fit-content;
  flex-basis: 17%;
}

.miners_type {
  height: fit-content;
  flex-basis: 10%;
}

.miners_workers {
  height: fit-content;
}

@media (max-width: 992px) {
  .miners_elem {
    font-size: 16px;
  }

  .miners_miner {
    flex-basis: 55%;
  }

  .miners_hashrate {
    flex-basis: 20%;
  }
}

@media (max-width: 768px) {
  .miners_elem {
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .miners_elem {
    font-size: 12px;
  }

  .miners_miner {
    flex-basis: 40%;
  }

  .miners_hashrate {
    flex-basis: 25%;
  }

  .miners_type {
    height: fit-content;
    flex-basis: 16%;
  }
}
</style>
