const locales = {
  en: {
    kaspa_mining_pool: 'Kaspa mining pool',
    language: 'Language',
    home: 'Home',
    pool_statistics: 'Pool statistics',
    start_mining: 'Start mining',
    current_hashrate: 'Current hashrate:',
    _3_hours_average: '3 hours average:',
    _24_hours_average: '24 hours average:',
    active_workers: 'Active workers:',
    effort_last_24h: 'Effort (last 24h):',
    effort_last_week: 'Effort (last week):',
    hashrate: 'Hashrate',
    effort: 'Effort',
    found_blocks: 'Found blocks',
    top_miners: 'Top 50 miners',
    miners: 'Miners',
    workers: 'Workers',
    miner: 'Miner',
    type: 'Type',
    //
    unpaid_balance: 'Unpaid balance:',
    min_payout: 'Min. payout:',
    est_earnings: 'Est. earnings',
    total_paid: 'Total paid:',
    earnings_1h: '1 hour',
    earnings_3h: '3 hours',
    earnings_12h: '12 hours',
    earnings_24h: '24 hours',
    earnings_week: 'Week',
    earnings_month: 'Month',
    earnings: 'Earnings',
    //
    dashboard: 'Dashboard',
    main: 'Main',
    payouts: 'Payouts',
    rewards: 'Rewards',
    blocks: 'Blocks',
    settings: 'Settings',
    //
    name: 'Name',
    current: '30 min.',
    _3_hours: '3 hours',
    _24_hours: '24 hours',
    last_share: 'Last share',
    //
    transaction: 'Transaction',
    time: 'Time',
    value: 'Amount',
    //
    block: 'Block',
    participation: 'Participation',
    //
    height: 'Height',
    difficulty: 'Difficulty',
    //
    workers_ip: 'Worker IP',
    //
    pool_fee: 'Pool fee',
    payout_system: 'Payout system',
    locations: 'Locations',
    pool_community: 'Pool community',
    monitoring: 'Monitoring',
    kaspa_project_links: 'Kaspa project links',
    kaspa_community: 'Kaspa community',
    next: 'Next',
    prev: 'Prev.',
    //
    pool_hashrate: 'Pool hashrate',
    net_hashrate: 'Network hashrate',
    min_payout_all: 'Minimum payout',
    min_payout_range: 'Range',
    support: 'Support',
    how_to_find_ip: 'How to find IP?',
    new_payout: 'New payout',
    price: 'Price',
    average: 'avg.',
    profit: 'Profit for the last month',
    //
    enter_wallet_address: 'Enter wallet address',
    enter_worker_name: 'Enter worker name',
    select_location: 'Select location',
    select_mode: 'Select mode',
    select_connection_type: 'Select connection type',
    click_for_copy: 'Click for copy',
    europe: 'Europe',
    asia: 'Asia',
    north_america: 'North America',
    south_america: 'South America',
    currency: 'Currency'
  },
  ru: {
    kaspa_mining_pool: 'KASPA майнинг пул',
    language: 'Язык',
    home: 'Главная',
    pool_statistics: 'Статистика пула',
    start_mining: 'Подключение',
    current_hashrate: 'Текущий хешрейт:',
    _3_hours_average: 'Средний за 3ч.',
    _24_hours_average: 'Средний за 24ч.',
    active_workers: 'Воркеры',
    effort_last_24h: 'Усилие (сутки)',
    effort_last_week: 'Усилие (неделя)',
    hashrate: 'Хешрейт',
    effort: 'Усилие',
    found_blocks: 'Найдено блоков',
    top_miners: 'Топ 50 майнеров',
    miners: 'Майнеры',
    workers: 'Воркеры',
    miner: 'Майнер',
    type: 'Тип',
    //
    unpaid_balance: 'Баланс:',
    min_payout: 'Мин. выплата:',
    est_earnings: 'Расчетный доход',
    total_paid: 'Всего выплачено:',
    earnings_1h: '1 час',
    earnings_3h: '3 часа',
    earnings_12h: '12 часов',
    earnings_24h: 'Сутки',
    earnings_week: 'Неделя',
    earnings_month: 'Месяц',
    earnings: 'Доход',
    //
    dashboard: 'Статистика майнера',
    main: 'Главная',
    payouts: 'Выплаты',
    rewards: 'Награды',
    blocks: 'Блоки',
    settings: 'Настройки',
    //
    name: 'Воркер',
    current: '30 мин.',
    _3_hours: '3 часа',
    _24_hours: '24 часа',
    last_share: 'Посл. шара',
    //
    transaction: 'Транзакция',
    time: 'Время',
    value: 'Кол-во',
    //
    block: 'Блок',
    participation: 'Участие',
    //
    height: 'Высота',
    difficulty: 'Сложность',
    //
    workers_ip: 'IP воркера',
    //
    pool_fee: 'Коммисия пула',
    payout_system: 'Система вознаграждения',
    locations: 'Расположение',
    pool_community: 'Сообщество пула',
    monitoring: 'Мониторинг',
    kaspa_project_links: 'Проект KASPA',
    kaspa_community: 'Сообщество проекта KASPA',
    //
    next: 'Далее',
    prev: 'Назад',
    //
    pool_hashrate: 'Хешрейт пула',
    net_hashrate: 'Хешрейт сети',
    min_payout_all: 'Минимальная выплата',
    min_payout_range: 'Диапазон',
    support: 'Поддержка',
    how_to_find_ip: 'Как узнать IP?',
    new_payout: 'Очередная выплата',
    price: 'Цена',
    average: 'сред.',
    profit: 'Доход за последний месяц',
    //
    enter_wallet_address: 'Введите адрес кошелька',
    enter_worker_name: 'Введите имя воркера',
    select_location: 'Выберите местоположение',
    select_mode: 'Выберите тип майнинга',
    select_connection_type: 'Выберите тип подключения',
    click_for_copy: 'Нажмите, чтобы скопировать',
    europe: 'Европа',
    asia: 'Азия',
    north_america: 'Северная Америка',
    south_america: 'Южная Америка',
    currency: 'Валюта'
  }
}
export default locales
