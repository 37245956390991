<template>
  <div class="container usr-blocks-container">
    <div class="content-container content full_height usr-blocks" :class="{ blur_bg: !loaded }">
      <div class="flex-container">
        <div class="usr-block-height">
          <div class="usr-block-elems">{{ $locale.height }}</div>
        </div>
        <div class="usr-block-diff">
          <div class="usr-block-elems">{{ $locale.difficulty }}</div>
        </div>
        <div class="usr-block-effort">
          <div class="usr-block-elems">{{ $locale.effort }}</div>
        </div>
        <div class="usr-block-time">
          <div class="usr-block-elems">{{ $locale.time }}</div>
        </div>
        <div class="usr-block-type">
          <div class="usr-block-elems">{{ $locale.type }}</div>
        </div>
      </div>
      <hr class="hr" />
      <div v-if="loaded">
        <div v-for="(block, index) in Response.blocks" v-bind:key="index">
          <div class="usr_blocks_row flex-container diver">
            <div class="usr-block-height">
              <div class="usr-block-elems">
                {{ block.height }}
              </div>
            </div>
            <div class="usr-block-diff">
              <div class="usr-block-elems">
                {{ block.difficulty.toFixed(2) }}
              </div>
            </div>
            <div class="usr-block-effort">
              <div class="usr-block-elems">
                {{ (block.effort).toFixed(2) }}%
              </div>
            </div>
            <div class="usr-block-time">
              <div class="usr-block-elems">{{ TimetampToDate(block.time) }}</div>
            </div>
            <div class="usr-block-type">
              <div class="usr-block-elems">{{ SoloPplns(block.solo) }}</div>
            </div>
          </div>
        </div>
        <PageNavigator @navigate="Navigate" :all_pages="Math.ceil(Response.all / Response.limit)"
          :current_page="Math.ceil(Response.offset / Response.limit) + 1" />
      </div>
      <PulseLoader :loading="!loaded" :color="'#1BBCF080'" />
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import PageNavigator from './PageNavigator.vue'

export default {
  components: {
    PulseLoader,
    PageNavigator
  },
  props: {
    wallet: {
      type: String
    }
  },
  data () {
    return {
      loaded: false,
      Response: {
        limit: 0,
        offset: 0,
        all: 0,
        blocks: [
          {
            height: 0,
            time: 0,
            hash: '',
            amount: 0.0,
            miner: '',
            difficulty: 0.0,
            effort: 0.0,
            solo: false
          }
        ]
      }
    }
  },
  mounted () {
    this.Query(32, 0)
  },
  methods: {
    isRedBlocks (amount) {
      if (amount === 0) {
        return true
      } else {
        return false
      }
    },
    TimetampToDate (timestamp) {
      const date = new Date(timestamp * 1000).toLocaleString('sv-SE', {
        hour12: false
      })
      return date
    },
    SoloPplns (type) {
      if (type === false) {
        return 'PPLNS'
      } else {
        return 'SOLO'
      }
    },
    AvgEffort () {
      let sum = 0
      let cnt = 0
      this.Response.blocks.forEach(function (block) {
        if (block.solo === true) {
          sum = sum + block.effort
          cnt++
        }
      })
      if (cnt > 0 && sum > 0) {
        return '(' + (sum / cnt).toFixed() + '% ' + 'avg.)'
      }
    },
    Navigate (page) {
      this.Query(this.Response.limit, this.Response.limit * (page - 1))
    },
    async Query (limit, offset) {
      const response = await fetch('/api/user/blocks/?wallet=' + this.wallet + '&limit=' + limit + '&offset=' + offset)
      if (response.ok) {
        this.Response = await response.json()
        this.loaded = true
      }
    }
  }
}
</script>

<style>
.red_block {
  color: var(--color-palite2);
}

.blue_block {
  color: var(--color-palite1);
}

.effort_avg {
  font-size: 12px;
}

.usr-blocks-container {
  padding-top: 2px;
}

.usr-block-elems {
  font-size: 16px;
  text-align: center;
}

.usr-blocks {
  padding: 10px;
}

.usr_blocks_row {
  border-radius: 5px;
  margin-bottom: 5px;
  text-decoration: none;
}

.usr-block-height {
  height: fit-content;
  flex-basis: 17%;
  padding: 4px;
  overflow: hidden;
}

.usr-block-diff {
  height: fit-content;
  flex-basis: 20%;
  padding: 4px;
  overflow: hidden;
}

.usr-block-effort {
  height: fit-content;
  flex-basis: 18%;
  padding: 4px;
  overflow: hidden;
}

.usr-block-time {
  height: fit-content;
  flex-basis: 30%;
  padding: 4px;
  overflow: hidden;
}

.usr-block-type {
  height: fit-content;
  padding: 4px;
  overflow: hidden;
}

@media (max-width: 992px) {
  .usr-block-height {
    flex-basis: 14%;
  }

  .usr-block-time {
    flex-basis: 28%;
  }
}

@media (max-width: 670px) {
  .usr-block-elems {
    font-size: 7px;
  }

  .effort_avg {
    font-size: 8px;
  }
}
</style>
