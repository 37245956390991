<template>
  <WalletForm />
  <!-- home board -->
  <HomeBoard />
  <!-- miners -->
  <MinersElem />
</template>

<script>
import WalletForm from '@/Components/WalletForm'
import MinersElem from '@/Components/MinersElem'
import HomeBoard from '@/Components/HomeBoard'
export default {
  components: {
    WalletForm,
    MinersElem,
    HomeBoard
  },
  mounted () {
    document.title = this.$locale.kaspa_mining_pool
  }
}
</script>

<style>
.welcome {
  padding: 20px;
  text-align: center;
}
</style>
