<template>
  <Doughnut :chart-data="chartData" :chart-options="chartOptions" />
</template>
<script>
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
ChartJS.register(ArcElement, Tooltip, Legend)
export default {
  name: 'DoughnutChart',
  components: { Doughnut },
  props: {
    Data: {
      default: []
    }
  },
  mounted () {
    this.chartData.datasets[0].data = this.Data
  },
  data () {
    return {
      chartData: {
        labels: ['Balance', ''],
        datasets: [
          {
            backgroundColor: [
              getComputedStyle(document.body).getPropertyValue('--color-palite1'),
              getComputedStyle(document.body).getPropertyValue('--color-background')
            ],
            data: []
          }
        ]
      },
      chartOptions: {
        cutout: '68%',
        borderWidth: '3',
        borderColor: getComputedStyle(document.body).getPropertyValue('--color-background2'),
        plugins: {
          tooltip: {
            enabled: false
          },
          legend: {
            display: false
          }
        }
      }
    }
  }
}
</script>
