import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '@/Pages/HomePage.vue'
import StatisticsPage from '@/Pages/StatisticsPage.vue'
import DashboardPage from '@/Pages/DashboardPage.vue'
import StartMiningPage from '@/Pages/StartMiningPage.vue'
import FAQpage from '@/Pages/FAQpage.vue'

export default createRouter({
  history: createWebHashHistory(),
  mode: 'history',
  routes: [
    { path: '/', component: HomePage },
    { path: '/statistics', component: StatisticsPage },
    { path: '/dashboard/:wallet', component: DashboardPage },
    { path: '/start_mining', component: StartMiningPage },
    { path: '/faq', component: FAQpage }
  ]
})
