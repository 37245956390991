<template>
  <div class="container">
    <div class="content-container content" :class="{ blur_bg: !loaded }">
      <div class="title">Last 50 found blocks</div>
      <div v-if="loaded" class="blocks_container">
        <div v-for="(block, index) in Response.blocks.slice(0, 50)" v-bind:key="index" class="">
          <div class="block_diver">
            <div class="block">DAAScore: {{ block.height }}</div>
            <div class="block">Hash: {{ block.hash }}</div>
            <div class="block">
              Difficulty: {{ block.difficulty.toFixed(2) }}
            </div>
            <div class="block">Reward: {{ block.amount }}</div>
            <div class="block">Effort: {{ block.effort.toFixed(2) }}%</div>
            <div class="block">{{ TimetampToDate(block.time) }}</div>
          </div>
        </div>
      </div>
      <PulseLoader :loading="!loaded" :color="'#1BBCF080'" />
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
  components: {
    PulseLoader
  },
  data () {
    return {
      loaded: false,
      Response: {
        blocks: [
          {
            height: 0,
            time: 0,
            hash: '',
            difficulty: 0.0,
            amount: 0.0,
            effort: 0.0
          }
        ]
      }
    }
  },
  async mounted () {
    const response = await fetch('/api/blocks')
    if (response.ok) {
      this.Response = await response.json()
      this.loaded = true
    }
  },
  methods: {
    TimetampToDate (timestamp) {
      const date = new Date(timestamp * 1000).toLocaleString()
      return date
    }
  }
}
</script>

<style>
.block_diver {
  border: solid var(--color-palite1);
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.blocks_container {
  padding: 10px;
}

.block {
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  text-align: start;
  box-shadow: 1px 1px 2px 2px var(--color-background);
  word-wrap: break-word;
}
</style>
