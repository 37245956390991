<template>
  <div class="container settings-container">
    <div class="content-container content full_height" :class="{ blur_bg: !loaded }">
      <div class="ip-form-container flex-container">
        <div class="flex-item">
          <div class="title">{{ $locale.min_payout }}</div>
          <form @submit.prevent>
            <input class="text-input" type="number" size="16" :placeholder="Response.min_payout"
              v-model="Request.min_payout" />
          </form>
        </div>
        <div class="flex-item">
          <div class="title">{{ $locale.workers_ip }}</div>
          <form @submit.prevent>
            <input class="text-input" type="text" size="15" :placeholder="Response.ip" v-model="Request.ip" />
            <button class="material-symbols-outlined buttons" @click="SettingsWrite()">
              Done
            </button>
          </form>
        </div>
      </div>
      <div class="flex-item">
        <span class="title error">{{ Response.error }} {{ Request.error }}
        </span>
        <span class="title message"> {{ Response.message }} </span>
        <div class="title">{{ $locale.min_payout_range }} 10-50000 kas</div>
        <div class="title">{{ $locale.how_to_find_ip }}</div>
        <div class="title">Windows: <a class="resourse small-links" target="_blank"
            href="https://api.ipify.org/">https://api.ipify.org/</a></div>
        <div class="title">HiveOS/RaveOS (Shell command): <span class="console">curl -kLs https://api.ipify.org</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      wallet: this.$route.params.wallet,
      loaded: false,
      Response: {
        min_payout: 0.0,
        ip: '',
        error: '',
        message: ''
      },
      Request: {
        min_payout: null,
        ip: null
      }
    }
  },
  async mounted () {
    const response = await fetch('/api/user/settings/?wallet=' + this.wallet)
    if (response.ok) {
      this.Response = await response.json()
      this.loaded = true
    }
  },
  methods: {
    async SettingsWrite () {
      if (this.Request.ip === null) {
        this.Response.error = 'Invalid ip'
        return
      }
      if (this.Request.min_payout === null) {
        this.Response.error = 'Invalid value'
        return
      }
      this.loaded = false
      const response = await fetch(
        '/api/user/settings/set_min_payout/?wallet=' +
        this.wallet +
        '&ip=' +
        this.Request.ip +
        '&min_payout=' +
        this.Request.min_payout.toString()
      )
      if (response.ok) {
        this.Response = await response.json()
        this.loaded = true
      }
    }
  }
}
</script>

<style>
.settings-container {
  padding-top: 2px;
}

.ip-form-container {
  width: fit-content;
  height: fit-content;
  margin-right: auto;
  padding: 5px;
}

.flex-item {
  padding: 10px;
}

.small-links {
  font-size: 18px;
}

.console {
  color: azure;
  font-size: 16px;
  background-color: rgb(40, 49, 55);
  padding: 5px;
  border-radius: 5px;
  word-break: break-all;
}
</style>
