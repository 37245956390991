<template>
  <div class="container flex-container">
    <div class="content-container content widget" :class="{ blur_bg: !loaded }" v-if="loaded">
      <div>
        <span class="title">{{ $locale.hashrate }}: </span>
        {{ Response.hashrate.toFixed(2) }} {{ Response.hashrate_unit }}
      </div>
      <div><span class="title">{{ $locale.workers }}: </span>{{ Response.workers }}</div>
      <div><span class="title">{{ $locale.miners }}: </span>{{ Response.miners }}</div>
    </div>
    <div class="content-container content widget">
      <div><span class="title">{{ $locale.pool_fee }}: </span>0.75%</div>
      <div><span class="title">{{ $locale.payout_system }}: </span>PPLNS, SOLO</div>
      <div>
        <span class="title">{{ $locale.min_payout_all }}: </span>10 kas
      </div>
    </div>
    <div class="content-container content widget">
      <div class="title">{{ $locale.support }}</div>
      <div>
        <a class="resourse" target="_blank" href="https://t.me/tim_512">Telegram</a>
      </div>
    </div>
    <div class="content-container content widget">
      <div class="title">{{ $locale.pool_community }}</div>
      <div>
        <a class="resourse" target="_blank" href="https://discord.gg/4BrZ4dXewB">Discord</a>
      </div>
      <div>
        <a class="resourse" target="_blank" href="https://t.me/kaspa_pool_chat">Telegram chat</a>
      </div>
    </div>
    <div class="content-container content widget">
      <div class="title">{{ $locale.monitoring }}</div>
      <div>
        <a class="resourse" target="_blank" href="https://t.me/kaspa_pool_org_bot">Telegram bot</a>
      </div>
      <div>
        <a class="resourse" target="_blank" href="https://www.witplex.com/MinerBox/">MinerBox
        </a>

        <a class="resourse" target="_blank" href="https://itunes.apple.com/us/app/minerbox/id1445878254">
          IOS/</a><a class="resourse" target="_blank"
          href="https://play.google.com/store/apps/details?id=com.witplex.minerbox_android">Android</a>
      </div>
    </div>
    <div class="content-container content widget">
      <div class="title">{{ $locale.kaspa_project_links }}</div>
      <a class="resourse" target="_blank" href="https://kaspa.org">Website, </a>
      <a class="resourse" target="_blank" href="https://github.com/kaspanet">Github</a>
    </div>

    <div class="content-container content widget">
      <div class="title">{{ $locale.kaspa_community }}</div>
      <a class="resourse" target="_blank" href="https://discord.gg/kS3SK5F36R">Discord, </a>
      <a class="resourse" target="_blank" href="https://t.me/kaspaenglish">Telegram EN, </a>
      <a class="resourse" target="_blank" href="https://t.me/kaspa_rus">Telegram RU</a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loaded: false,
      Response: {
        base: {
          hashrate: 0.0,
          hashrate_unit: 'H/s',
          workers: 0,
          miners: 0
        }
      }
    }
  },
  async mounted () {
    const response = await fetch('/api/base')
    if (response.ok) {
      this.Response = await response.json()
      this.loaded = true
    }
  }
}
</script>

<style></style>
