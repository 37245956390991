<template>
  <MenuHeader/>
  <router-view></router-view>
</template>

<script>
import MenuHeader from '@/Components/MenuHeader'

export default {
  components: {
    MenuHeader
  }
}
</script>

<style>
</style>
