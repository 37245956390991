<template>
  <div class="container">
    <div class="flex-container">
      <!---->
      <div class="content-container content pool-hashrate" :class="{ blur_bg: !loaded }">
        <div>
          <span class="title small">{{ $locale.time }}</span>
          <span class="text_end">
            <span class="title small">{{ $locale.hashrate }}</span>
          </span>
        </div>
        <hr class="div_line">
        <div>
          <span class="title small">{{ $locale.current }}</span>
          <span class="text_end">
            <span class="small">
              {{ statistics.current_hashrate.hashrate.toFixed(2) }}
              {{ statistics.current_hashrate.hashrate_unit }}
            </span>
          </span>
        </div>
        <div>
          <span class="title small">{{ $locale._3_hours }}</span>
          <span class="text_end">
            <span class="small">
              {{ statistics.hashrate3h.hashrate.toFixed(2) }}
              {{ statistics.hashrate3h.hashrate_unit }}
            </span>
          </span>
        </div>
        <div>
          <span class="title small">{{ $locale._24_hours }}</span>
          <span class="text_end">
            <span class="small">
              {{ statistics.hashrate24h.hashrate.toFixed(2) }}
              {{ statistics.hashrate24h.hashrate_unit }}
            </span>
          </span>
        </div>
        <hr class="div_line">
        <div>
          <span class="title small">{{ $locale.active_workers }}</span>
          <span class="text_end">
            <span class="small">
              {{ statistics.active_workers }}
            </span>
          </span>
        </div>
      </div>
      <!---->
      <div class="content-container content pool_hashrate_chart" :class="{ blur_bg: !loaded }">
        <div class="title small text_center">
          {{ $locale.pool_hashrate }}
        </div>
        <LineChart :Chart="statistics.hashrate_chart" :Label="'Hashrate'" :beginAtHalfY="true" :height="150"
          v-if="loaded" />
      </div>
      <!---->
      <div class="content-container content net_hashrate_chart" :class="{ blur_bg: !loaded }">
        <div class="title small text_center">
          {{ $locale.net_hashrate }}
        </div>
        <LineChart :Chart="statistics.network_hashrate_chart" :Label="'Hashrate'" :beginAtHalfY="true" :height="150"
          v-if="loaded" />
      </div>
    </div>
    <div class="flex-container">
      <!---->
      <div class="content-container content pool-effort" :class="{ blur_bg: !loaded }">
        <div>
          <span class="title small">{{ $locale.time }}</span>
          <span class="text_end">
            <span class="title small">{{ $locale.effort }}</span>
          </span>
        </div>
        <hr class="div_line">
        <div>
          <span class="title small">{{ $locale._24_hours }}</span>
          <span class="text_end">
            <span class="small">
              {{ (100 / statistics.luck).toFixed(1) }}%
            </span>
          </span>
        </div>
        <div>
          <span class="title small">{{ $locale.earnings_week }}</span>
          <span class="text_end">
            <span class="small">
              {{ (100 / statistics.luck_week).toFixed(1) }}%
            </span>
          </span>
        </div>
      </div>
      <!---->
      <div class="content-container content pool-effort-chart" :class="{ blur_bg: !loaded }">
        <div class="title small text_center">{{ $locale.effort }}</div>
        <LineChart :Chart="statistics.effort_chart" :Label="'Effort'" :beginAtHalfY="true" :height="150" v-if="loaded" />
      </div>
      <!---->
      <div class="content-container content pool-blocks-chart" :class="{ blur_bg: !loaded }">
        <div class="title small text_center">{{ $locale.found_blocks }}</div>
        <div class="bar-container">
          <BarChart :Chart="{ dataset: statistics.found_blocks_chart.data }" :beginAtZeroY="true" :height="122"
            v-if="loaded" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/Components/LineChart.vue'
import BarChart from '@/Components/BarChart.vue'
export default {
  name: 'StatisticsPage',
  components: { LineChart, BarChart },
  data () {
    return {
      loaded: false,
      statistics: {
        active_workers: 0,
        found_blocks: 0,
        luck: 1.0,
        luck_week: 1.0,
        current_hashrate: {
          hashrate_unit: 'H/s',
          hashrate: 0.0
        },
        hashrate3h: {
          hashrate_unit: 'H/s',
          hashrate: 0.0
        },
        hashrate24h: {
          hashrate_unit: 'H/s',
          hashrate: 0.0
        },
        hashrate_chart: {
          hashrate_unit: 'H/s',
          data: []
        },
        network_hashrate_chart: {
          hashrate_unit: 'H/s',
          data: []
        },
        effort_chart: {
          data: [],
          hashrate_unit: '%'
        },
        found_blocks_chart: {
          data: []
        }
      }
    }
  },
  async mounted () {
    const response = await fetch('/api/statistics')
    if (response.ok) {
      this.statistics = await response.json()
      this.loaded = true
    }
    this.statistics.effort_chart.hashrate_unit = '%'
  }
}
</script>

<style>
.pos_abs {
  position: absolute;
  padding-left: 50px;
}

.pool-effort-chart {
  flex-basis: 38%;
}

.pool-blocks-chart {
  flex-basis: 38%;
}

.pool_hashrate_chart {
  flex-basis: 38%;
}

.net_hashrate_chart {
  flex-basis: 38%;
}

.bar-container {
  padding: 10px;
  /*padding-right: 30px;
  padding-left: 10px;
  padding-top: 30px;*/
}

.pool-hashrate {
  flex-grow: 1;
  padding: 10px;
}

.pool-effort {
  flex-grow: 1;
  padding: 10px;
}

@media (max-width: 992px) {
  .net_hashrate_chart {
    display: none;
  }

  .pool-blocks-chart {
    display: none;
  }

  .pool_hashrate_chart {
    flex-basis: 70%;
  }

  .pool-effort-chart {
    flex-basis: 70%;
  }
}

@media (max-width: 768px) {
  .pool_hashrate_chart {
    flex-basis: 60%;
  }

  .pool-effort-chart {
    flex-basis: 60%;
  }
}

@media (max-width: 576px) {
  .pool_hashrate_chart {
    flex-basis: 100%;
  }

  .pool-effort-chart {
    flex-basis: 100%;
  }
}
</style>
