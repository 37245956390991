<template>
  <div class="container">
    <div class="content-container wallet-form-container">
      <form @submit.prevent>
        <input class="text-input input-wallet" name="wallet" type="text" size="67" placeholder="miner address"
          v-model="wallet" />
        <button class="buttons material-symbols-outlined" @click="GoToDashboard()">
          search
        </button>
      </form>
      <div class="title error">{{ error }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      error: '',
      wallet: null
    }
  },
  methods: {
    GoToDashboard () {
      this.wallet = this.wallet.replace(' ', '')
      if (((this.wallet.length + 6) === 67) || ((this.wallet.length + 6) === 69)) {
        this.wallet = 'kaspa:' + this.wallet
      }
      if (this.wallet.length !== 67) {
        if (this.wallet.length !== 69) {
          this.error = 'Invalid address'
          return
        }
      }
      this.$router.push('/dashboard/' + this.wallet)
    }
  }
}
</script>

<style>
.wallet-form-container {
  margin-top: 18px;
  width: fit-content;
  height: fit-content;
  margin-right: auto;
  margin-left: auto;
}

.input-wallet {
  margin-left: 10px;
  width: 80%;
}
</style>
