<template>
  <!-- dashboard menu -->
  <div class="container">
    <div class="content-container flex-container">
      <div>
        <a
          class="dashboard-menu"
          :class="{ menu_selector: PageSelector == 'main' }"
          @click="ChangePage('main')"
          >{{ $locale.main }}</a
        >
      </div>
      <div>
        <a
          class="dashboard-menu"
          :class="{ menu_selector: PageSelector == 'payouts' }"
          @click="ChangePage('payouts')"
          >{{ $locale.payouts }}</a
        >
      </div>
      <div>
        <a
          class="dashboard-menu"
          :class="{ menu_selector: PageSelector == 'rewards' }"
          @click="ChangePage('rewards')"
          >{{ $locale.rewards }}</a
        >
      </div>
      <div>
        <a
          class="dashboard-menu"
          :class="{ menu_selector: PageSelector == 'blocks' }"
          @click="ChangePage('blocks')"
          > {{ $locale.blocks }} </a
        >
      </div>
      <div>
        <a
          class="dashboard-menu"
          :class="{ menu_selector: PageSelector == 'settings' }"
          @click="ChangePage('settings')"
          >{{ $locale.settings }}</a
        >
      </div>
      <div class="wallet-flex-container">
        <a
          class="wallet-link"
          target="_blank"
          :href="'https://explorer.kaspa.org/addresses/' + this.wallet"
          >{{ wallet.slice(0, 12) + '*****' + wallet.slice(54) }}</a
        >
      </div>
    </div>
  </div>
  <!-- dashboard main -->
  <DashboardMain :wallet="wallet" v-if="PageSelector == 'main'" />
  <!-- payouts -->
  <PayoutsElem :wallet="wallet" v-if="PageSelector == 'payouts'" />
  <!-- rewards -->
  <RewardsElem :wallet="wallet" v-if="PageSelector == 'rewards'" />
  <!-- blocks -->
  <UserBlocksElem :wallet="wallet" v-if="PageSelector == 'blocks'" />
  <!-- settings -->
  <SettingsElem :wallet="wallet" v-if="PageSelector == 'settings'" />
</template>

<script>
import DashboardMain from '@/Components/DashboardMain.vue'
import PayoutsElem from '@/Components/PayoutsElem'
import RewardsElem from '@/Components/RewardsElem'
import UserBlocksElem from '@/Components/UserBlocksElem'
import SettingsElem from '@/Components/SettingsElem'
export default {
  components: {
    DashboardMain,
    PayoutsElem,
    RewardsElem,
    SettingsElem,
    UserBlocksElem
  },

  data () {
    return {
      PageSelector: 'main',
      wallet: this.$route.params.wallet
    }
  },
  methods: {
    ChangePage (page) {
      this.PageSelector = page
    }
  },
  mounted () {
    document.title = this.$locale.dashboard
  }
}
</script>

<style>
.dashboard-menu {
  font-family: 'Play', sans-serif;
  color: var(--color-palite1);
  font-size: 18px;
  padding: 5px;
  margin-left: 2px;
  cursor: pointer;
}
.menu_selector {
  color: var(--color-palite3);
  box-shadow: inset 0px -3px 0px var(--color-palite3);
}
.dashboard-menu:hover {
  color: var(--color-palite3);
  /* box-shadow: inset 0px -3px 0px var(--color-palite3);*/
}
.wallet-link {
  color: var(--color-palite1);
  text-decoration: none;
  font-size: 20px;
  padding: 10px;
}
.wallet-link:hover {
  color: var(--color-palite3);
}
.wallet-flex-container {
  margin-left: auto;
}
@media (max-width: 670px) {
  .wallet-flex-container {
    margin-left: auto;
    display: none;
  }
  .dashboard-menu {
    font-size: 14px;
    padding-left: 3px;
    padding-right: 3px;
  }
}
</style>
