<template>
    <div class="container">
        <div class="content-container content barchart-earnings">
            <div class="title small">{{ $locale.profit }}</div>
            <BarChartMonth :beginAtZeroY="true" :Chart="Response.data" :height="130" v-if="loaded" />
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import BarChartMonth from './BarChartMonth.vue'
export default {
    components: { BarChartMonth },
    data () {
        return {
            wallet: this.$route.params.wallet,
            loaded: false,
            Response: {
                data: [{}]
            }
        }
    },
    async mounted () {
        const response = await fetch('/api/user/earnings_chart/?wallet=' + this.wallet)
        if (response.ok) {
            this.Response = await response.json()
            this.loaded = true
        }
    }
}
</script>
<style>
.barchart-earnings {
    margin-top: 10px;
    padding: 5px;
    min-height: 160px;
}
</style>
