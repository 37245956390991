<template>
  <EarningsChart />
  <div class="container payouts-container">
    <div class="content-container content middle_height payouts" :class="{ blur_bg: !loaded }">
      <div class="flex-container">
        <div class="txid">
          <div class="elems">{{ $locale.transaction }}</div>
        </div>
        <div class="payout_time">
          <div class="elems">{{ $locale.time }}</div>
        </div>
        <div class="payout_value">
          <div class="elems">{{ $locale.value }}</div>
        </div>
      </div>
      <hr class="hr" />
      <div v-if="loaded">
        <div v-for="(payout, index) in Response.payouts" v-bind:key="index">
          <a :href="'https://explorer.kaspa.org/txs/' + payout.txid" target="_blank"
            class="payout_row flex-container diver">
            <div class="txid">
              <div class="elems">
                {{ AddressCut(payout.txid) }}
              </div>
            </div>
            <div class="payout_time">
              <div class="elems">
                {{ TimetampToDate(payout.time) }}
              </div>
            </div>
            <div class="payout_value">
              <div class="elems">
                {{ payout.value.toFixed(2) }}
              </div>
            </div>
          </a>
        </div>
        <PageNavigator @navigate="Navigate" :all_pages="Math.ceil(Response.all / Response.limit)"
          :current_page="Math.ceil(Response.offset / Response.limit) + 1" />
      </div>
      <PulseLoader :loading="!loaded" :color="'#1BBCF080'" />
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import PageNavigator from './PageNavigator.vue'
import EarningsChart from './EarningsChart.vue'
export default {
  components: {
    PulseLoader,
    PageNavigator,
    EarningsChart
  },
  props: {
    wallet: {
      type: String
    }
  },
  data () {
    return {
      test: {
        dataset: [10, 6, 9, 6, 9, 9, 9, 9, 9, 10, 11, 12, 13, 14, 15, 10, 17, 18, 16, 10, 10, 10, 10, 24, 25, 26, 27, 28, 29, 30]
      },
      windowWidth: window.innerWidth,
      loaded: false,
      Response: {
        limit: 0,
        offset: 0,
        all: 0,
        payouts: [
          {
            time: 0,
            txid: '',
            value: 0
          }
        ]
      }
    }
  },
  mounted () {
    this.Query(24, 0)
  },
  methods: {
    Navigate (page) {
      this.Query(this.Response.limit, this.Response.limit * (page - 1))
    },
    TimetampToDate (timestamp) {
      const date = new Date(timestamp * 1000).toLocaleString('sv-SE', {
        hour12: false
      })
      return date
    },
    AddressCut (address) {
      if (this.windowWidth < 1200) {
        if (this.windowWidth < 600) {
          return address.slice(0, 7) + '***' + address.slice(60)
        } else {
          return address.slice(0, 12) + '***' + address.slice(54)
        }
      } else {
        return address
      }
    },
    async Query (limit, offset) {
      const response = await fetch('/api/user/payouts/?wallet=' + this.wallet + '&limit=' + limit + '&offset=' + offset)
      if (response.ok) {
        this.Response = await response.json()
        this.loaded = true
      }
    }
  }
}
</script>

<style>
.payouts-container {
  padding-top: 2px;
}

.elems {
  font-size: 16px;
  text-align: start;
  text-decoration: none;
}

.payouts {
  padding: 10px;
}

.payout_row {
  border-radius: 5px;
  margin-bottom: 5px;
  text-decoration: none;
}

.txid {
  height: fit-content;
  flex-basis: 60%;
  padding: 4px;
  margin-left: 4px;
  overflow: hidden;
}

.payout_time {
  height: fit-content;
  flex-basis: 20%;
  padding: 4px;
}

.payout_value {
  height: fit-content;
  padding: 4px;
  border-radius: 5px;
}

@media (max-width: 1200px) {
  .txid {
    flex-basis: 40%;
  }

  .payout_time {
    flex-basis: 30%;
  }

  .elems {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .txid {
    flex-basis: 28%;
  }

  .payout_time {
    flex-basis: 35%;
  }

  .elems {
    font-size: 10px;
  }
}
</style>
