<template>
  <div class="container">
    <div class="content-container middle_height content" :class="{ blur_bg: !loaded }">
      <div class="workers_title title" v-if="!isfound && loaded">
        Workers not found ¯\_(ツ)_/¯
      </div>
      <div v-if="loaded && isfound" class="workers">
        <div class="workers_title title">
          {{ $locale.workers }} ({{ activeWorkers }}/{{ Response.workers.length }})
        </div>
        <div class="flex-container">
          <div class="workername">
            <div class="worker">{{ $locale.name }}</div>
          </div>
          <div class="current_hashrate">
            <div class="worker">{{ $locale.current }}</div>
          </div>
          <div class="hashrate3h">
            <div class="worker">{{ $locale._3_hours }}</div>
          </div>
          <div class="hashrate24h">
            <div class="worker">{{ $locale._24_hours }}</div>
          </div>
          <div class="last_share_time">
            <div class="worker">{{ $locale.last_share }}</div>
          </div>
        </div>
        <hr class="hr" />
        <div v-for="(worker, index) in Response.workers" v-bind:key="index">
          <div class="flex-container worker_row diver" :class="{
            inactive_status: worker.current_hashrate.hashrate == 0
          }">
            <div class="workername">
              <div class="worker">
                {{ worker.name }}
              </div>
            </div>
            <div class="current_hashrate">
              <div class="worker">
                {{ worker.current_hashrate.hashrate.toFixed(2) }}
                {{ worker.current_hashrate.hashrate_unit }}
              </div>
            </div>
            <div class="hashrate3h">
              <div class="worker">
                {{ worker.hashrate3h.hashrate.toFixed(2) }}
                {{ worker.hashrate3h.hashrate_unit }}
              </div>
            </div>
            <div class="hashrate24h">
              <div class="worker">
                {{ worker.hashrate24h.hashrate.toFixed(2) }}
                {{ worker.hashrate24h.hashrate_unit }}
              </div>
            </div>
            <div class="last_share_time">
              <div class="worker">
                {{ UnixTimeToDate(worker.last_share_time) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PulseLoader :loading="!loaded" :color="'#1BBCF080'" />
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
  components: {
    PulseLoader
  },
  props: {
    wallet: {
      type: String
    }
  },
  data () {
    return {
      interval: null,
      interval_wd: null,
      timer_active: false,
      loaded: false,
      isfound: false,
      activeWorkers: 0,
      Response: {
        workers: [
          {
            name: {
              type: String
            },
            current_hashrate: {
              hashrate: 0.0,
              hashrate_unit: 'H/s'
            },
            hashrate3h: {
              hashrate: 0.0,
              hashrate_unit: 'H/s'
            },
            hashrate24h: {
              hashrate: 0.0,
              hashrate_unit: 'H/s'
            },
            last_share_time: 0
          }
        ]
      }
    }
  },
  methods: {
    UnixTimeToDate (timestamp) {
      const date = new Date(timestamp * 1000).toLocaleString('sv-SE', {
        hour12: false
      })
      const out = date.split(' ')
      return out[1]
      /* const time = Math.floor(Date.now() / 1000)
      const div = time - timestamp
      if (div < 60) {
        return div + ' sec ago'
      }
      if (div < 3600) {
        return Math.floor(div / 60) + ' min ago'
      }
      return Math.floor(div / 3600) + ' hours ago' */
    },

    async Query () {
      // console.log('query w')
      this.counter = 60
      const response = await fetch('/api/user/workers/?wallet=' + this.wallet)
      if (response.ok) {
        this.Response = await response.json()
        if (this.Response.workers === null) {
          this.loaded = true
          return
        }
        this.Response.workers.sort(function (a, b) {
          return a.name.replace(/[^+\d]/g, '') - b.name.replace(/[^+\d]/g, '')
        })
        this.activeWorkers = 0
        this.Response.workers.forEach((worker) => {
          if (worker.current_hashrate.hashrate > 0) {
            this.activeWorkers++
          }
        })
        this.isfound = true
        this.loaded = true
      }
    },

    watchDog () {
      if (!document.hidden && !this.timer_active) {
        this.startTimer()
        this.timer_active = true
        // console.log('start timer w')
      } else {
        if (document.hidden && this.timer_active) {
          this.stopTimer()
          this.timer_active = false
          // console.log('stop timer w')
        }
      }
    },

    startTimer () {
      this.stopTimer()
      this.Query()
      this.interval = window.setInterval(() => {
        this.Query()
      }, 60000)
    },

    stopWatchDog () {
      if (this.interval_wd) {
        window.clearInterval(this.interval_wd)
      }
    },

    stopTimer () {
      if (this.interval) {
        window.clearInterval(this.interval)
      }
    }
  },
  mounted () {
    this.interval_wd = window.setInterval(() => {
      this.watchDog()
    }, 100)
  },
  beforeUnmount () {
    this.stopWatchDog()
    this.stopTimer()
  }
}
</script>

<style>
.hr {
  margin-bottom: 10px;
  background-color: var(--color-palite7);
  height: 1px;
  border: 0px;
}

.worker_row {
  border-radius: 5px;
  margin-bottom: 5px;
}

.inactive_status {
  background-color: var(--color-palite2);
}

.workers {
  padding: 10px;
}

.workername {
  height: fit-content;
  flex-basis: 25%;
  padding: 4px;
}

.current_hashrate {
  height: fit-content;
  flex-basis: 20%;
  padding: 4px;
}

.hashrate3h {
  height: fit-content;
  flex-basis: 20%;
  padding: 4px;
}

.hashrate24h {
  height: fit-content;
  flex-basis: 20%;
  padding: 4px;
}

.last_share_time {
  height: fit-content;
  padding: 4px;
}

.worker {
  font-size: 18px;
  text-align: start;
}

.workers_title {
  text-align: center;
}

@media (max-width: 992px) {
  .workername {
    flex-basis: 20%;
  }

  .current_hashrate {
    flex-basis: 15%;
  }

  .hashrate3h {
    flex-basis: 15%;
  }

  .hashrate24h {
    flex-basis: 15%;
  }
}

@media (max-width: 768px) {
  .worker {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .worker {
    font-size: 12px;
  }
}
</style>
